<template>
  <div class="carousel-games">
    <BCarousel
      v-model="carouselIndex"
      :autoplay="false"
      :indicator-inside="false"
      :indicator="false"
      :arrow="false"
      :key="carouselKey"
      @change="onCarouselChange"
    >
      <BCarouselItem v-for="game of games" :key="game.id">
        <BLoading :is-full-page="false" :active="isGameLoading"></BLoading>
        <ItemMobile v-if="isMobile" :game="game" @click="onItemClick" @show-statistics="$emit('show-statistics')" />
        <ItemDesktop v-else :game="game" @click="onItemClick" @show-statistics="$emit('show-statistics')" />
      </BCarouselItem>
    </BCarousel>
  </div>
</template>

<script>
import { BCarousel, BCarouselItem } from 'buefy/dist/esm/carousel';
import { BLoading } from 'buefy/dist/esm/loading';
import { mapMutations } from 'vuex';

export default {
  name: 'CarouselGames',
  components: {
    BCarousel,
    BCarouselItem,
    BLoading,
    ItemDesktop: () => import('@/components/Sections/GolTipster-v2/General/CarouselItemDesktop'),
    ItemMobile: () => import('@/components/Sections/GolTipster-v2/General/CarouselItemMobile'),
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
    games: {
      type: Array,
      default: () => [],
    },
    selectedGameId: {
      type: Number,
      default: 0,
    },
    isPremiumUser: {
      type: Boolean,
      default: false,
    },
    isGameLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      carouselIndex: 0,
      carouselKey: 5000,
    };
  },
  watch: {
    selectedGameId(newValue) {
      const gameIndex = this.games.findIndex(game => game.game_id === newValue);
      if (gameIndex !== -1) {
        this.carouselIndex = gameIndex;
      }
    },
    games(newValue) {
      if (newValue && newValue.length) {
        const nearestGame = newValue[0];
        if (this.isPremiumUser) {
          this.$emit('change', nearestGame);
        } else if (newValue.length > 1) {
          const nearestRoundGames = newValue.filter(
            game => game.matchday_abbreviation === nearestGame.matchday_abbreviation,
          );
          this.$emit('change', nearestRoundGames.pop());
        } else {
          this.$emit('change', nearestGame);
        }
      }
    },
  },
  methods: {
    ...mapMutations(['SET_PREMIUM_MESSAGE_V2']),
    onCarouselChange(carouselIndex) {
      if (this.isPremiumUser) {
        this.$emit('change', this.games[carouselIndex]);
      } else if (carouselIndex !== this.carouselIndex) {
        this.carouselKey += 1;
        this.SET_PREMIUM_MESSAGE_V2(true);
      }
    },
    onItemClick(side) {
      if (this.isPremiumUser) {
        if (side === 'left' && this.carouselIndex > 0) {
          this.carouselIndex -= 1;
        } else if (side === 'right') {
          this.carouselIndex += 1;
        }
      } else {
        this.SET_PREMIUM_MESSAGE_V2(true);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.carousel-games {
  width: 100%;
}
</style>
<style lang="scss">
.carousel-games {
  & div.loading-overlay {
    & > div.loading-background {
      background: rgba(0, 0, 0, 0.5) !important;
      border-radius: 16px;
    }

    & > div.loading-icon:after {
      border: 3px solid #cbee6b;
      border-right-color: transparent;
      border-top-color: transparent;
    }
  }
}
</style>
